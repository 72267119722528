<div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" [style.display]="finished ? 'none' : 'flex'" [attr.aria-hidden]="finished">
            <div class="modal-body">
                <!-- Close -->
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
                <!-- Image -->
                <div class="text-center">
                    <img src="assets/img/illustrations/seattle-broker-team-the-bruck-team-illustration.png" alt="..." class="img-fluid mb-4" style="width: 200px;">
                </div>
                <!-- Heading -->
                <h2 class="font-weight-bold text-center mb-1" id="modalTitle">
                    Contact {{ isGeneric() ? "Us" : brokerName }}
                </h2>
                <!-- Text -->
                <p class="font-size-lg text-center text-muted mb-6 mb-md-8">
                    {{ isGeneric() ? "We" : "I" }} can help you acquire or sell your property.
                </p>
                <!-- Form -->
                <broker-contact-form [broker-name]="brokerName"></broker-contact-form>
            </div>
        </div>

        <!-- "Thank you" page -->
        <div class="modal-content" [style.display]="finished ? 'flex' : 'none'" [attr.aria-hidden]="!finished">
            <div class="modal-body">
                <!-- Close -->
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
                <!-- Image -->
                <div class="text-center">
                    <img src="./assets/img/illustrations/illustration-1.png" alt="..." class="img-fluid mb-3" style="width: 200px;">
                </div>
                <!-- Heading -->
                <h2 class="font-weight-bold text-center mb-1" id="modalTitle">
                    Thank You
                </h2>
                <!-- Text -->
                <p class="font-size-lg text-center text-muted">
                    Thank you for contacting {{ isGeneric() ? "us. We" : "me. I" }} will reach back out to you shortly.
                </p>
            </div>
        </div>
    </div>
</div>
