import { Component, Input, ViewChild } from '@angular/core';
import { BrokerContactFormComponent } from '../../forms/broker-contact-form/broker-contact-form.component';

/**
 * Toggle this with `<button data-toggle="modal" data-target="team-contact-modal[broker-name~='Amnon'] .modal"></button>`.
 */
@Component({
    selector: 'team-contact-modal',
    templateUrl: './broker-contact-modal.component.html'
})
export class BrokerContactModalComponent {
    @ViewChild(BrokerContactFormComponent) form!: BrokerContactFormComponent;

    @Input('team-member-name') brokerName: string = '';

    /**
     * Helper method for determining general vs. specific form text
     */
    isGeneric(): boolean {
        return this.brokerName === '';
    }

    get finished(): boolean {
        if (this.form === undefined) {
            return false;
        } else {
            return this.form.finished;
        }
    }
}
