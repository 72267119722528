import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { BrokerContactModalComponent } from './broker-contact-modal.component';
import { BrokerContactFormModule } from '../../forms/broker-contact-form/broker-contact-form.module';


@NgModule({
    declarations: [BrokerContactModalComponent],
    imports: [
        CommonModule,
        FormsModule,

        BrokerContactFormModule
    ],
    exports: [
        BrokerContactModalComponent
    ]
})
export class BrokerContactModalModule { }
