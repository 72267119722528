import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, shareReplay, tap } from "rxjs/operators";

export interface BrokerJSON {
    id: number,
    name: string,
    firstName: string,       // filled in by controller
    title: string,
    email: string,
    description: string,
    biography: string,
    brokerImageURL: string,  // filled in by controller
    brokerImageAlt: string   // filled in by controller
}

@Injectable({
    providedIn: 'root'
})
export class BrokersService {
    private _brokers$: Observable<BrokerJSON[]> | null = null;

    constructor(private _http: HttpClient) { }

    public getAllBrokers(): Observable<BrokerJSON[]> {
        if (this._brokers$ == null) {
            this._brokers$ = this._loadBrokers();
        }
        return this._brokers$;
    }

    public getBrokerByName(name: string): Observable<BrokerJSON> {
        if (this._brokers$ == null) {
            this._brokers$ = this._loadBrokers();
        }
        return this._brokers$.pipe(
            map(brokers => brokers.filter(broker => broker.firstName.toLowerCase() == name)[0])
        );
    }

    private _loadBrokers(): Observable<BrokerJSON[]> {
        return this._http.get<BrokerJSON[]>('/api/broker/broker-list').pipe(
            shareReplay()
        );
    }
}
